var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showCard)?_c('v-card',{staticClass:"px-8 py-4",attrs:{"outlined":""}},[_c('languageSelector',{attrs:{"addContainer":true,"cssProps":'language-selector-in-row max-w-select-lang-login'}}),_c('br'),(_vm.email.length > 0)?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('Reset password requested', { email: _vm.email }))}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":_vm.$t('Password'),"type":"password","prepend-inner-icon":"mdi-lock","required":"","validate-on-blur":"","outlined":"","dense":"","hide-details":"auto","rules":[
            (v) => !!v || _vm.$t('Password is required'),
            (v) => (v && v.length > 4) || _vm.$t('Password is too short'),
          ]},model:{value:(_vm.pass),callback:function ($$v) {_vm.pass=$$v},expression:"pass"}}),_c('v-text-field',{staticClass:"mb-6",attrs:{"label":_vm.$t('Repeat password'),"type":"password","prepend-inner-icon":"mdi-lock","required":"","validate-on-blur":"","outlined":"","dense":"","hide-details":"auto","rules":[
            (v) => !!v || _vm.$t('Password is required'),
            (v) => (v && v.length > 4) || _vm.$t('Password is too short'),
          ]},model:{value:(_vm.repeatPass),callback:function ($$v) {_vm.repeatPass=$$v},expression:"repeatPass"}}),_c('div',{staticClass:"text-sm-right"},[_c('v-btn',{staticClass:"ml-0 mr-0",attrs:{"depressed":"","type":"submit","loading":_vm.loading,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Reset password")))])],1)],1)]:[_c('preloader')]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }