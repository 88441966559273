<template>
  <div>
    <v-card outlined class="px-8 py-4" v-if="showCard">
      <languageSelector
        :addContainer="true"
        :cssProps="'language-selector-in-row max-w-select-lang-login'"
      />

      <br />

      <template v-if="email.length > 0">
        <p v-html="$t('Reset password requested', { email: email })" />

        <v-form @submit.prevent="resetPassword" ref="form" lazy-validation>
          <v-text-field
            :label="$t('Password')"
            v-model="pass"
            type="password"
            prepend-inner-icon="mdi-lock"
            required
            validate-on-blur
            outlined
            dense
            class="mb-4"
            hide-details="auto"
            :rules="[
              (v) => !!v || $t('Password is required'),
              (v) => (v && v.length > 4) || $t('Password is too short'),
            ]"
          ></v-text-field>

          <v-text-field
            :label="$t('Repeat password')"
            v-model="repeatPass"
            type="password"
            prepend-inner-icon="mdi-lock"
            required
            validate-on-blur
            outlined
            dense
            class="mb-6"
            hide-details="auto"
            :rules="[
              (v) => !!v || $t('Password is required'),
              (v) => (v && v.length > 4) || $t('Password is too short'),
            ]"
          ></v-text-field>

          <div class="text-sm-right">
            <v-btn
              depressed
              class="ml-0 mr-0"
              type="submit"
              :loading="loading"
              color="primary"
              >{{ $t("Reset password") }}</v-btn
            >
          </div>
        </v-form>
      </template>
      <template v-else>
        <preloader></preloader>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      success: "",
      email: "",
      pass: "",
      repeatPass: "",
      loading: false,
      showCard: true,
    };
  },
  created() {
    this.getResetPasswordEmail();
  },

  methods: {
    getResetPasswordEmail() {
      this.success = "";

      this.$request.get(
        "/resetpasswordemail/" + this.$route.params.hash,
        null,
        (res) => {
          if (res.success) {
            this.email = res.data;
          } else {
            this.showCard = false;
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
    resetPassword() {
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        this.$request.post(
          "/resetpassword/" + this.$route.params.hash,
          {
            email: this.email,
            password: this.pass,
            passwordrepeat: this.repeatPass,
          },
          (res) => {
            this.loading = false;

            if (res.success) {
              this.success = res.message;
              const toaster = {
                message: res.message,
                timeout: false,
                type: "SUCCESS",
                toastClassName: ["toaster-color-success", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-check-circle",
              };
              this.$store.dispatch("setToaster", toaster);
              setTimeout(() => {
                this.$router.replace({ name: "login" });
                this.$router.go(1);
              }, this.$config.hideMessageTimeout);
            } else {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
          }
        );
      }
    },
  },
};
</script>

<style>
</style>
